<template>
    <section class="select-property-container" v-if="hasMainLayoutInitialized">
        <div class="header-container">
            <p class="header">Please select a property you want to<br> start a new project for.</p>
            <el-input
                class="search-input w-50 m-2 hide-in-mobile"
                placeholder="Search"
                v-model="input"
            >
                <template #prefix>
                    <el-icon class="el-input__icon"><Search /></el-icon>
                </template>
            </el-input>
            <el-icon :size="30" class="el-input__icon hide-in-desktop"><Search /></el-icon>
        </div>
        <div class="content-container" v-if="!isLoading">
            <el-scrollbar class="property-list" v-if="properties.length">
                <PropertyDetailsCard class="property-card" :class="{ 'is-active': activeIndex === property.id }" v-for="property in properties" :key="property.id" viewType="list" :property="property" @click="changeActiveState(property.id)"/>
            </el-scrollbar>
            <div class="blur" v-if="properties.length"></div>
            <NoPropertyComponent v-if="properties.length <= 0 || properties.length === null"/>
        </div>
        <div class="action-container" v-if="properties.length && !isLoading">
            <el-button
                type="primary"
                class="yellow-button"
                round
                :loading="submitting"
                @click="routeToPostProject"
                >NEXT
            </el-button>
            <p class="add-property-cta">Property not in the list? <span @click="routeToAddPropertyForm">Add new property</span></p>
        </div>
        <Loader v-show="!properties.length && isLoading"/>
    </section>
</template>
<script>
import { Search } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import Loader from '@/core/components/ui/Loader.vue';
import { PROPERTY_STORE } from '@/store/modules/property-details';
import { USERS_STORE } from '@/store/modules/users';

import { PROJECT_POST_ROUTE } from '../../project-post/routes';
import NoPropertyComponent from '../components/NoPropertyComponent.vue';
import PropertyDetailsCard from '../components/PropertyDetailsCard.vue';

export default defineComponent({
  components: {
    Search,
    NoPropertyComponent,
    PropertyDetailsCard,
    Loader
  },
  data() {
    return {
      totalProperties: 0,
      properties: [],
      activeIndex: null,
    };
  },
  created() {
    if (this.wsRole && !this.isWsAdmin) {
      this.routeToDashboard();
      return;
    }

    this.loadProperties();
  },

  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized', 'wsRole', 'isWsAdmin']),
  },

  methods: {
    ...mapActions(['setIsLoading']),
    ...mapActions(PROPERTY_STORE, ['getProperties']),

    async loadProperties() {
      this.initializing = true;
      await this.getProperties().then((response) => {
        this.totalProperties = response.totalCount;
        this.properties = response.data;
      })
        .finally(() => {
          this.setIsLoading(false);
        });
    },

    routeToPostProject() {
      this.$router.push({ name: PROJECT_POST_ROUTE, params: { propertyId: this.activeIndex } });
    },

    routeToDashboard() {
      this.$router.push({ name: 'dashboard' }).then(() => { this.$router.go(); });
    },

    routeToAddPropertyForm() {
      this.$router.push({
        name: 'properties',
      });
    },

    changeActiveState(id) {
      this.activeIndex = id;
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

:deep(.el-scrollbar__bar) {
    display: block !important;
}
:deep(.el-scrollbar__bar.is-vertical) {
    top: 0;
    right: 0;
    width: 6px !important;
}
.select-property-container * p {
  margin: 0;
  font-family: Mulish;
  color: #0C0F4A;
}
.select-property-container {
    height: calc(100% - 90px);
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;
    margin-left: 10px;
    margin-right: 20px;
    max-width: 1030px;
    position: relative;
}

.header-container {
    display:flex;
    justify-content: space-between;
    align-items: center;
    .header {
        font-family: Mulish;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0px;
        text-align: left;
    }

    .search-input {
        width: 350px;
    }

    :deep(.el-input__inner) {
        background: rgba(12, 15, 74, 0.05);
        border: none;
        color: #111;
        border-radius: 30px;
        font-size: 14px;
        padding: 20px 20px 20px 40px;
        font-family: Arial;
    }

    :deep(.el-input__prefix) {
        left:10px;
        color:#111;
    }

    .hide-in-desktop {
        display: none;
    }
}
.content-container {
    height: 100%;
    overflow: hidden;
    position: relative;

    .property-list {
        :deep(.el-scrollbar__view) {
            display:flex;
            flex-direction: column;
            gap: 2rem;
            height: auto !important;
            padding: 0 .7rem 2rem 0;
        }
        .property-card.is-active {
            border: 2px solid #FFA500;
            outline-color: #FFA500;
            transition: 0.2s ease-in-out;
        }
    }

    .blur {
        position:absolute;
        background: linear-gradient(180deg, rgba(241, 246, 251, 0) 0%, #F1F6FB 35.5%);
        width: 100%;
        height: 35px;
        bottom: -20px;
        left:0;
        right:0;
    }
}
.action-container {
    display:flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;

    .yellow-button {
        background: #FAA100;
        border-radius: 7px;
        color: #fff;
        outline: none;
        border: none;
        width: 150px;
    }
    .add-property-cta {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.25px;
        color: rgba(12, 15, 74, 0.5);

        span {
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0.25px;
            color: #FAA100;
            cursor: pointer;
        }
    }
}

@include  media-md-max-width() {
  .select-property-container {
      height: calc(100% - 135px);
      margin: 1rem 25px 0 25px;

      .header-container {
        .header {
            font-size: 21px;
            font-weight: 600;
            line-height: 25.59px;
        }
    }
  }
}

@include media-sm-max-width() {
   .select-property-container {
       height: calc(100% - 120px);
      .header-container {
       .hide-in-mobile {
            display: none;
        }
        .hide-in-desktop {
            display: inline-block;
        }
    }
  }
}

@include media-xs-max-width() {
.select-property-container {
    height: calc(100% - 50px);
    .header-container {
        .header {
            font-size: 18px;
            font-weight: 600;
            line-height: 22.59px;
        }
    }
    .content-container {
        .blur {
            height: 10px;
            bottom: -5px;
        }
    }
}
  .action-container {
    flex-direction: column;
    gap: .8rem;

    .yellow-button {
        width: 100%;
        height: 42px;
    }
  }
}

@media screen and (max-width: 414px) {
.select-property-container {
    .header-container {
        .header {
            font-size: 15px;
            font-weight: 600;
            line-height: 22.59px;
        }
    }
}
}
</style>
